import React, { useMemo, useEffect } from "react"
import backgroundVideoDesktop from "@images/home-page/backgroundVideoDesktop.mp4"
import backgroundVideoMobile from "@images/home-page/backgroundVideoMobile.mp4"
import backgroundImage from "@images/home-page/backgroundImage.jpg"
import backgroundImageMobile from "@images/home-page/backgroundImageMobile.jpeg"
import VideoSection from "@common/CommonSections/VideoSection/VideoSection"
import useWindowDimensions from "@hooks/useWindowDimensions"
import { MOBILE_MAX_WIDTH } from "@constants"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import loadable from "@loadable/component"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import { CUSTOM_MEMBERSHIP_BADGES, SECOND_CATCH_UP_SECTION_TITLE } from "./constants"
import { ProudSectionContainer } from "./Home.style"

const OffersSection = loadable(() =>
  import("@common/CommonSections/OffersSection/OffersSection")
)
const AboutSection = loadable(() => import("./components/AboutSection"))

const ExpertiseSection = loadable(() =>
  import("./components/ExpertiseSectionWrapper/ExpertiseSectionWrapper")
)

const DonateBanner = loadable(() => import("@common/DonateBanner/DonateBanner"))

const CaseStudiesSection = loadable(() =>
  import("@common/CommonSections/CaseStudiesSection/CaseStudiesSection")
)

const ProudSection = loadable(() =>
  import("@common/CommonSections/ProudSection/ProudSection")
)

const CrawlableSecondCatchUpSection = loadable(() =>
  import("@common/CommonSections/CrawlableSecondCatchUpSection")
)

const LinkButton = loadable(() => import("@common/LinkButton"))

const CASE_STUDIES_INDEXES = [
  { index: 3, order: 4 },
  { index: 11, order: 1 },
  { index: 9, order: 2 },
  { index: 33, order: 3 },
]

const Home = ({ caseStudies }) => {
  const { width, isMobile } = useWindowDimensions()
  const isDesktop = useMemo(() => width > MOBILE_MAX_WIDTH, [width])
  const isMounted = useMemo(() => width !== 0, [width])

  useEffect(
    () => () => {
      ScrollTrigger.getAll().forEach(st => st.kill())
    },
    []
  )

  return (
    isMounted && (
      <div className="home">
        <DonateBanner />
        <VideoSection
          video={isDesktop ? backgroundVideoDesktop : backgroundVideoMobile}
          poster={isMobile ? backgroundImageMobile : backgroundImage}
          title={
            <>
              Emerging technologies <br /> made simple
            </>
          }
          description={
            <>
              Software innovation company leveraging AI, XR, and
              state-of-the-art technologies to create the products of tomorrow.
            </>
          }
        />
        <OffersSection />
        <ExpertiseSection />
        <CaseStudiesSection
          caseStudies={caseStudies}
          indexes={CASE_STUDIES_INDEXES}
          heading="Case studies"
          isHugeMargin
        />
        <AboutSection />
        {isMobile !== undefined && !isMobile && (
          <ProudSectionContainer>
            <ProudSection customBadges={CUSTOM_MEMBERSHIP_BADGES} fourBadgesInRow hideSecondRow withoutTitle />
          </ProudSectionContainer>
        )}
        <CrawlableSecondCatchUpSection
          isAnimated
          title={SECOND_CATCH_UP_SECTION_TITLE}
        >
          <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
            Let's chat
          </LinkButton>
        </CrawlableSecondCatchUpSection>
      </div>
    )
  )
}

export default Home
