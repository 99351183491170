import expertiesBackgroundFirst from "@images/home-page/expertiesBackgroundFirst.png"
import expertiesBackgroundSecond from "@images/home-page/expertiesBackgroundSecond.png"
import expertiesBackgroundThird from "@images/home-page/expertiesBackgroundThird.png"
import expertiesBackgroundFourth from "@images/home-page/expertiesBackgroundFourth.png"
import expertiesBackgroundFifth from "@images/home-page/expertiesBackgroundFifth.png"
import expertiesBackgroundSixth from "@images/home-page/expertiesBackgroundSixth.png"
import expertiesBackgroundSeventh from "@images/home-page/expertiesBackgroundSeventh.png"
import expertiesMobileFirst from "@images/home-page/expertiesMobileFirst.svg"
import expertiesMobileSecond from "@images/home-page/expertiesMobileSecond.svg"
import expertiesMobileThird from "@images/home-page/expertiesMobileThird.svg"
import expertiesMobileFourth from "@images/home-page/expertiesMobileFourth.svg"
import expertiesMobileFifth from "@images/home-page/expertiesMobileFifth.svg"
import expertiesMobileSixth from "@images/home-page/expertiesMobileSixth.svg"
import expertiesMobileSeventh from "@images/home-page/expertiesMobileSeventh.svg"
import { MEMBERSHIP_BADGES } from "../../constants"

export const MAIN_PAGE_ABOUT_HEADING = "About"
export const MAIN_PAGE_ABOUT_PARAGRAPH =
  "Impressit is a premium software development agency with strong expertise in building cloud and mobile applications."
export const MAIN_PAGE_ABOUT_COMPANY_BUTTON_TEXT = "More about the company"

const currentYear = new Date().getFullYear()

export const ABOUT_COMPANY_STATISTICS = [
  {
    title: "42",
    description: "full-time highly motivated employees",
  },
  {
    title: "56+",
    description: "awesome projects delivered",
  },
  {
    title: "89%",
    description: "of the team are senior and middle engineers",
  },
  {
    title: currentYear - 2018,
    description: "years on the market",
  },
]

export const EXPERTISES = [
  {
    title: "Product discovery",
    description: `
              During the product discovery phase, our team will conduct
              a comprehensive analysis of your idea and will turn it into a clear
              vision of your product and an in-depth development plan.
          `,
    backgroundImage: expertiesBackgroundFirst,
    iconPath: [expertiesMobileFirst, null],
    path: "/discovery-phase",
  },
  {
    title: "UX/UI design",
    description: `
              You want your product to be attractive yet simple. You want it to be
              functional yet easy to navigate. With Impressit, you can be sure 
              that your product is in good hands. 
          `,
    backgroundImage: expertiesBackgroundSecond,
    iconPath: [expertiesMobileSecond, null],
    path: "/design",
  },
  {
    title: "Mobile app development",
    description: `
              With numerous mobile applications appearing on the market daily, 
              it may be challenging to succeed. Finding the right mobile application
              development partner is the thing that can make it or break it.
          `,
    backgroundImage: expertiesBackgroundThird,
    iconPath: [expertiesMobileThird, null],
    path: "/mobile-development",
  },
  {
    title: "Web app development",
    description: `
              When deciding to turn your business idea into a real product and are looking
              to hire web developer, you want to find a reliable and knowledgeable 
              web development partner.
          `,
    backgroundImage: expertiesBackgroundFourth,
    iconPath: [expertiesMobileFourth, null],
    path: "/web-development",
  },
  {
    title: "DevOps engineering",
    description: `
              You may have a team of brilliant engineers and talented managers
              but it won’t be complete without someone who will build and maintain
              reliable and secure development infrastructure.
          `,
    backgroundImage: expertiesBackgroundFifth,
    iconPath: [expertiesMobileFifth, null],
    path: "/devops",
  },
  {
    title: "AI/ML development",
    description: `
              Integrating AI into operations simplifies tasks, saving 
              valuable time previously consumed by repetitive activities.
          `,
    backgroundImage: expertiesBackgroundSixth,
    iconPath: [expertiesMobileSixth, null],
    path: "/artificial-intelligence",
    lightenImage: true,
  },
  {
    title: "Extended reality",
    description: `
              Extended Reality (XR) enhances modern digital products by integrating 
              Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) to 
              create immersive, interactive experiences.
          `,
    backgroundImage: expertiesBackgroundSeventh,
    iconPath: [expertiesMobileSeventh, null],
    path: "/extended-reality",
    lightenImage: true,
  },
]

export const CUSTOM_MEMBERSHIP_BADGES = [
  { index: 0, ...MEMBERSHIP_BADGES.techUK },
  { index: 1, ...MEMBERSHIP_BADGES.lvivITCluster },
  { index: 2, ...MEMBERSHIP_BADGES.ITUkraineAssociation },
  { index: 3, ...MEMBERSHIP_BADGES.forbes },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "We got you interested?"
